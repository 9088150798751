/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import { RiArrowRightSLine } from "react-icons/ri"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ToolsPage = () => (
  <Layout className="tools-page">
    <SEO 
      title="FREE HR Technology Tools & Calculators" 
      description="FREE interactive tools to help you make informed decisions about HR software. Compare costs, calculate ROI, and evaluate HR technology options at no cost."
      keywords={[
        "free HR software calculators",
        "free HR technology tools",
        "HR software cost comparison",
        "free HRIS ROI calculator",
        "HR software evaluation tools",
        "free benefits ROI calculator",
        "employee engagement assessment",
        "diversity inclusion metrics",
        "performance review builder",
        "HR compliance tools"
      ]}
    />
    
    <div sx={{
      bg: 'primaryMuted',
      pt: [4, 5],
      pb: [3, 4]
    }}>
      <div sx={{
        maxWidth: '1200px',
        mx: 'auto',
        px: 3
      }}>
        <h1 sx={{
          fontSize: ['2rem', '2.5rem'],
          fontWeight: 700,
          textAlign: 'center',
          mb: 3,
          color: 'primary'
        }}>
          Free HR Tools & Calculators
        </h1>
        
        <p sx={{
          fontSize: '1.1rem',
          textAlign: 'center',
          maxWidth: '800px',
          mx: 'auto',
          mb: 4,
          lineHeight: 1.5,
          color: 'text'
        }}>
          Welcome to our collection of free, interactive HR tools designed to help you make data-driven decisions 
          about your human resources technology and processes. Whether you're evaluating HR software options, 
          calculating ROI on employee benefits, or building better performance reviews, these tools provide 
          valuable insights tailored to your organization's specific needs - all at absolutely no cost.
        </p>
        
        <p sx={{
          fontSize: '1.1rem',
          textAlign: 'center',
          maxWidth: '800px',
          mx: 'auto',
          mb: 0,
          lineHeight: 1.5,
          color: 'text'
        }}>
          Each tool is mobile-friendly and easy to use, with results you can save or share with your team. 
          Simply select any of our free tools from the categories below to get started.
        </p>
      </div>
    </div>

    <div sx={{ maxWidth: '1200px', mx: 'auto', px: 3, pt: 4, pb: 5 }}>
      <div sx={{
        textAlign: 'center',
        mb: 5
      }}>
        <Link to="/contact" sx={{
          display: 'inline-flex',
          alignItems: 'center',
          gap: 2,
          bg: 'primary',
          color: 'white',
          py: 2,
          px: 4,
          borderRadius: '6px',
          fontSize: '1.1rem',
          fontWeight: 600,
          textDecoration: 'none',
          transition: 'transform 0.2s ease, box-shadow 0.2s ease',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
            color: 'white'
          }
        }}>
          Need a Custom HR Tool? Get in Touch <RiArrowRightSLine />
        </Link>
      </div>

      {/* Recruitment & Compensation Tools Section */}
      <h2 sx={{ 
        fontSize: ['1.5rem', '1.65rem'], 
        mb: 5, 
        color: 'primary',
        textAlign: 'center'
      }}>Recruitment & Compensation Tools</h2>
      <div sx={{ display: 'grid', gridTemplateColumns: ['1fr', '1fr 1fr'], gap: 4, mb: 5 }}>
        <div sx={{ 
          p: 4, 
          bg: 'white', 
          borderRadius: '8px',
          boxShadow: '0 5px 20px rgba(0,0,0,0.1)',
          transition: 'all 0.3s ease',
          '&:hover': {
            transform: 'translateY(-5px)',
            boxShadow: '0 8px 25px rgba(0,0,0,0.15)'
          }
        }}>
          <Link to="/tools/time-to-hire-calculator" sx={{ textDecoration: 'none', color: 'text' }}>
            <h3 sx={{ fontSize: ['1.3rem', '1.4rem'], color: 'primary', mb: 3 }}>Time to Hire Calculator</h3>
            <p sx={{ fontSize: '1rem', mb: 3 }}>Estimate recruitment timelines based on position type, seniority, and hiring processes. Perfect for HR planning.</p>
            <div sx={{ display: 'flex', alignItems: 'center' }}>
              <span sx={{ fontSize: '1rem', color: 'primary', fontWeight: 'bold' }}>Try Calculator</span>
              <RiArrowRightSLine sx={{ ml: 1 }} />
            </div>
          </Link>
        </div>

        <div sx={{ 
          p: 4, 
          bg: 'white', 
          borderRadius: '8px',
          boxShadow: '0 5px 20px rgba(0,0,0,0.1)',
          transition: 'all 0.3s ease',
          '&:hover': {
            transform: 'translateY(-5px)',
            boxShadow: '0 8px 25px rgba(0,0,0,0.15)'
          }
        }}>
          <Link to="/tools/salary-benchmarking" sx={{ textDecoration: 'none', color: 'text' }}>
            <h3 sx={{ fontSize: ['1.3rem', '1.4rem'], color: 'primary', mb: 3 }}>Salary Benchmarking Tool</h3>
            <p sx={{ fontSize: '1rem', mb: 3 }}>Compare compensation packages against industry standards based on role, location, experience, and company size.</p>
            <div sx={{ display: 'flex', alignItems: 'center' }}>
              <span sx={{ fontSize: '1rem', color: 'primary', fontWeight: 'bold' }}>Try Calculator</span>
              <RiArrowRightSLine sx={{ ml: 1 }} />
            </div>
          </Link>
        </div>
      </div>

      {/* Employee Experience Tools Section */}
      <h2 sx={{ 
        fontSize: ['1.5rem', '1.65rem'], 
        mb: 5, 
        color: 'primary',
        textAlign: 'center'
      }}>Employee Experience Tools</h2>
      <div sx={{ display: 'grid', gridTemplateColumns: ['1fr', '1fr 1fr'], gap: 4, mb: 5 }}>
        <div sx={{ 
          p: 4, 
          bg: 'white', 
          borderRadius: '8px',
          boxShadow: '0 5px 20px rgba(0,0,0,0.1)',
          transition: 'all 0.3s ease',
          '&:hover': {
            transform: 'translateY(-5px)',
            boxShadow: '0 8px 25px rgba(0,0,0,0.15)'
          }
        }}>
          <Link to="/tools/engagement-assessment" sx={{ textDecoration: 'none', color: 'text' }}>
            <h3 sx={{ fontSize: ['1.3rem', '1.4rem'], color: 'primary', mb: 3 }}>Employee Engagement Assessment</h3>
            <p sx={{ fontSize: '1rem', mb: 3 }}>Evaluate current engagement levels and receive actionable insights for improving workplace satisfaction.</p>
            <div sx={{ display: 'flex', alignItems: 'center' }}>
              <span sx={{ fontSize: '1rem', color: 'primary', fontWeight: 'bold' }}>Start Assessment</span>
              <RiArrowRightSLine sx={{ ml: 1 }} />
            </div>
          </Link>
        </div>

        <div sx={{ 
          p: 4, 
          bg: 'white', 
          borderRadius: '8px',
          boxShadow: '0 5px 20px rgba(0,0,0,0.1)',
          transition: 'all 0.3s ease',
          '&:hover': {
            transform: 'translateY(-5px)',
            boxShadow: '0 8px 25px rgba(0,0,0,0.15)'
          }
        }}>
          <Link to="/tools/turnover-cost-calculator" sx={{ textDecoration: 'none', color: 'text' }}>
            <h3 sx={{ fontSize: ['1.3rem', '1.4rem'], color: 'primary', mb: 3 }}>Employee Turnover Cost Calculator</h3>
            <p sx={{ fontSize: '1rem', mb: 3 }}>Calculate the real financial impact of employee departures including direct, indirect, and hidden costs.</p>
            <div sx={{ display: 'flex', alignItems: 'center' }}>
              <span sx={{ fontSize: '1rem', color: 'primary', fontWeight: 'bold' }}>Try Calculator</span>
              <RiArrowRightSLine sx={{ ml: 1 }} />
            </div>
          </Link>
        </div>
      </div>

      {/* Management & Compliance Tools Section */}
      <h2 sx={{ 
        fontSize: ['1.5rem', '1.65rem'], 
        mb: 5, 
        color: 'primary',
        textAlign: 'center'
      }}>Management & Compliance Tools</h2>
      <div sx={{ display: 'grid', gridTemplateColumns: ['1fr', '1fr 1fr'], gap: 4, mb: 5 }}>
        <div sx={{ 
          p: 4, 
          bg: 'white', 
          borderRadius: '8px',
          boxShadow: '0 5px 20px rgba(0,0,0,0.1)',
          transition: 'all 0.3s ease',
          '&:hover': {
            transform: 'translateY(-5px)',
            boxShadow: '0 8px 25px rgba(0,0,0,0.15)'
          }
        }}>
          <Link to="/tools/performance-review-builder" sx={{ textDecoration: 'none', color: 'text' }}>
            <h3 sx={{ fontSize: ['1.3rem', '1.4rem'], color: 'primary', mb: 3 }}>Performance Review Builder</h3>
            <p sx={{ fontSize: '1rem', mb: 3 }}>Create comprehensive and fair performance reviews with role-specific templates and expert guidance.</p>
            <div sx={{ display: 'flex', alignItems: 'center' }}>
              <span sx={{ fontSize: '1rem', color: 'primary', fontWeight: 'bold' }}>Start Building</span>
              <RiArrowRightSLine sx={{ ml: 1 }} />
            </div>
          </Link>
        </div>

        <div sx={{ 
          p: 4, 
          bg: 'white', 
          borderRadius: '8px',
          boxShadow: '0 5px 20px rgba(0,0,0,0.1)',
          transition: 'all 0.3s ease',
          '&:hover': {
            transform: 'translateY(-5px)',
            boxShadow: '0 8px 25px rgba(0,0,0,0.15)'
          }
        }}>
          <Link to="/tools/compliance-risk-assessment" sx={{ textDecoration: 'none', color: 'text' }}>
            <h3 sx={{ fontSize: ['1.3rem', '1.4rem'], color: 'primary', mb: 3 }}>Compliance Risk Assessment</h3>
            <p sx={{ fontSize: '1rem', mb: 3 }}>Identify potential HR compliance gaps and get actionable recommendations for risk mitigation.</p>
            <div sx={{ display: 'flex', alignItems: 'center' }}>
              <span sx={{ fontSize: '1rem', color: 'primary', fontWeight: 'bold' }}>Start Assessment</span>
              <RiArrowRightSLine sx={{ ml: 1 }} />
            </div>
          </Link>
        </div>

        <div sx={{ 
          p: 4, 
          bg: 'white', 
          borderRadius: '8px',
          boxShadow: '0 5px 20px rgba(0,0,0,0.1)',
          transition: 'all 0.3s ease',
          '&:hover': {
            transform: 'translateY(-5px)',
            boxShadow: '0 8px 25px rgba(0,0,0,0.15)'
          }
        }}>
          <Link to="/tools/diversity-inclusion-tracker" sx={{ textDecoration: 'none', color: 'text' }}>
            <h3 sx={{ fontSize: ['1.3rem', '1.4rem'], color: 'primary', mb: 3 }}>Diversity & Inclusion Progress Tracker</h3>
            <p sx={{ fontSize: '1rem', mb: 3 }}>Measure and visualize D&I initiatives with industry benchmarking and actionable insights.</p>
            <div sx={{ display: 'flex', alignItems: 'center' }}>
              <span sx={{ fontSize: '1rem', color: 'primary', fontWeight: 'bold' }}>Try Tracker</span>
              <RiArrowRightSLine sx={{ ml: 1 }} />
            </div>
          </Link>
        </div>

        <div sx={{ 
          p: 4, 
          bg: 'white', 
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          opacity: 0.7
        }}>
          <h3 sx={{ fontSize: ['1.3rem', '1.4rem'], color: 'primary', mb: 3 }}>More Tools Coming Soon</h3>
          <p sx={{ fontSize: '1rem', textAlign: 'center' }}>We're working on more helpful tools for HR professionals.</p>
        </div>
      </div>

      {/* Compliance Risk Assessment Tool */}
      <div sx={{
        p: 4,
        bg: 'white',
        borderRadius: '8px',
        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        transition: 'transform 0.2s ease, box-shadow 0.2s ease',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: '0 4px 15px rgba(0,0,0,0.15)'
        }
      }}>
        <Link to="/tools/compliance-risk-assessment" sx={{ textDecoration: 'none', color: 'inherit' }}>
          <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Compliance Risk Assessment Tool</h2>
          <p sx={{ mb: 4, color: 'gray.7', lineHeight: 1.6 }}>
            Identify potential HR compliance gaps and get actionable recommendations for risk mitigation. Evaluate compliance across employment law, workplace safety, benefits administration, and more.
          </p>
          <div sx={{ display: 'flex', alignItems: 'center' }}>
            <span sx={{ fontWeight: 'bold', color: 'primary' }}>Try Calculator</span>
            <span sx={{ ml: 2 }}>→</span>
          </div>
        </Link>
      </div>

      {/* FAQ Section */}
      <div sx={{
        maxWidth: '800px',
        mx: 'auto',
        mt: 5,
        mb: 4,
        px: [3, 0]
      }}>
        <h2 sx={{ 
          fontSize: ['1.5rem', '1.65rem'],
          mb: 4,
          color: 'primary',
          textAlign: 'center'
        }}>Frequently Asked Questions</h2>

        <div sx={{
          display: 'grid',
          gap: 4
        }}>
          <div>
            <h3 sx={{ 
              fontSize: ['1.1rem', '1.2rem'],
              color: 'primary',
              mb: 2
            }}>Are these HR tools really free?</h3>
            <p sx={{ 
              fontSize: '1rem',
              color: 'text',
              lineHeight: 1.6
            }}>Yes, all our HR tools and calculators are completely free to use. We believe in providing value to HR professionals and helping them make data-driven decisions without any cost barriers.</p>
          </div>

          <div>
            <h3 sx={{ 
              fontSize: ['1.1rem', '1.2rem'],
              color: 'primary',
              mb: 2
            }}>How accurate are the calculations and assessments?</h3>
            <p sx={{ 
              fontSize: '1rem',
              color: 'text',
              lineHeight: 1.6
            }}>Our tools use industry-standard formulas and benchmarks, regularly updated with the latest data. While they provide reliable estimates, we recommend using them as guidance alongside your professional judgment and organization-specific context.</p>
          </div>

          <div>
            <h3 sx={{ 
              fontSize: ['1.1rem', '1.2rem'],
              color: 'primary',
              mb: 2
            }}>Can I save or export my results?</h3>
            <p sx={{ 
              fontSize: '1rem',
              color: 'text',
              lineHeight: 1.6
            }}>Yes, most of our tools allow you to download your results as PDF reports or spreadsheets. You can also save your progress and return later to update your inputs or recalculate as needed.</p>
          </div>

          <div>
            <h3 sx={{ 
              fontSize: ['1.1rem', '1.2rem'],
              color: 'primary',
              mb: 2
            }}>How often are the tools updated?</h3>
            <p sx={{ 
              fontSize: '1rem',
              color: 'text',
              lineHeight: 1.6
            }}>We regularly update our tools to reflect changes in industry standards, compliance requirements, and best practices. Our data and calculations are reviewed quarterly to ensure accuracy and relevance.</p>
          </div>

          <div>
            <h3 sx={{ 
              fontSize: ['1.1rem', '1.2rem'],
              color: 'primary',
              mb: 2
            }}>Do you offer custom tools for specific needs?</h3>
            <p sx={{ 
              fontSize: '1rem',
              color: 'text',
              lineHeight: 1.6
            }}>Yes, we can develop custom HR tools tailored to your organization's specific needs. Contact us to discuss your requirements and how we can help create specialized solutions for your HR processes.</p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ToolsPage 